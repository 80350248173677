import React from 'react';
import GlobalStyle from './src/styles/GlobalStyle';
import 'intersection-observer';
require('prism-themes/themes/prism-ghcolors.css');
require('prismjs/plugins/command-line/prism-command-line.css');

export const wrapPageElement = ({ element }) => {
  return (
    <>
      <GlobalStyle />
      {element}
    </>
  );
};
